import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackBars } from '@fingo/lib/hooks';
import useLogin from './useLoginV2';
import {
  authEmailVar,
  selectedAuthTypeVar,
} from '../graphql/reactive-variables';
import useEmailValidation from './useEmailValidation';
import { SIGN_UP } from '../constants/auth';

const useLoginForm = (redirectTo, landingTag) => {
  const { addAlert } = useSnackBars();
  const { validateEmail } = useEmailValidation(landingTag);
  const { signIn } = useLogin(redirectTo);
  const authEmail = authEmailVar();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Correo no válido')
      .required('El correo es obligatorio'),
    password: Yup.string().when('email', ([email], schema) => {
      if (!!email && email === authEmail) {
        return schema.required('La contraseña es obligatoria');
      }
      return schema;
    }),
  });

  const formik = useFormik({
    initialValues: { email: authEmail, password: '' },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!authEmail) {
        validateEmail({
          variables: { email: values.email },
          onCompleted: ({ checkUsernameEmail }) => {
            authEmailVar(values.email);
            if (!checkUsernameEmail) {
              addAlert({
                id: 'no-user-associated',
                message: 'Bienvenido. Registra tus datos para acceder a Fingo',
                color: 'success',
                severity: 'success',
              });
              selectedAuthTypeVar(SIGN_UP);
            }
          },
        });
      } else {
        signIn({
          variables: { email: authEmail, password: values.password },
        });
        authEmailVar('');
      }
      setSubmitting(false);
    },
  });
  return formik;
};

export default useLoginForm;
