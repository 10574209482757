import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSnackBars } from '@fingo/lib/hooks';
import {
  authEmailVar,
  selectedAuthTypeVar,
} from '../graphql/reactive-variables';
import useEmailValidation from './useEmailValidation';
import { SIGN_IN } from '../constants/auth';
import useRegister from './useRegister';
import useLogin from './useLoginV2';

const useRegisterForm = (redirectTo, landingTag) => {
  const { addAlert } = useSnackBars();
  const { t } = useTranslation();
  const { validateEmail } = useEmailValidation(landingTag);
  const authEmail = authEmailVar();
  const { signUp } = useRegister();
  const { signIn } = useLogin(redirectTo);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Correo no válido')
      .required('El correo es obligatorio'),
    firstName: Yup.string().when('email', ([email], schema) => {
      if (!!email && email === authEmail) {
        return schema.required('El nombre es obligatorio');
      }
      return schema;
    }),
    lastName: Yup.string().when('email', ([email], schema) => {
      if (!!email && email === authEmail) {
        return schema.required('El apellido es obligatorio');
      }
      return schema;
    }),
    phoneNumber: Yup.string().when('email', ([email], schema) => {
      if (!!email && email === authEmail) {
        return schema.required('El teléfono es obligatorio');
      }
      return schema;
    }),
    password: Yup.string().when('email', ([email], schema) => {
      if (!!email && email === authEmail) {
        return schema.required('La contraseña es obligatoria');
      }
      return schema;
    }),
  });

  const formik = useFormik({
    initialValues: {
      email: authEmail,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (!authEmail) {
        validateEmail({
          variables: { email: values.email },
          onCompleted: ({ checkUsernameEmail }) => {
            authEmailVar(values.email);
            if (checkUsernameEmail) {
              addAlert({
                id: 'user-already-registered',
                message: 'Bienvenido. Inicia Sesión para acceder a Fingo',
                color: 'success',
                severity: 'success',
              });
              selectedAuthTypeVar(SIGN_IN);
            }
            setSubmitting(false);
          },
        });
      } else {
        signUp({
          variables: {
            email: values.email.toLowerCase(),
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: `+${t('Phone prefix')}${values.phoneNumber}`,
            password: values.password,
          },
          onCompleted: () => {
            signIn({
              variables: { email: authEmail, password: values.password },
            });
            authEmailVar('');
          },
          onError: ({ message }) => {
            addAlert({
              id: 'register-error',
              message,
              color: 'error',
              severity: 'error',
            });
            setSubmitting(false);
          },
        });
      }
    },
  });

  return formik;
};

export default useRegisterForm;
